import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import Theme from '@audiencetown/owk-uitk/dist/components/Theme';
import LoginVariantOne from './components/LoginVariantOne';
import LoginVariantTwo from './components/LoginVariantTwo';
import LoginVariantThree from './components/LoginVariantThree';

import Layout from './components/layout';
import LoginForm from './components/loginForm';
import SetPassword from './components/passwords/setPassword';
import ResetPassword from './components/passwords/resetPassword';
import config from './config';

axios.defaults.baseURL = config.baseUrl;

function App() {
  const order = Math.floor(Math.random() * 3);

  const LayoutLogin =
    order === 0
      ? LoginVariantOne
      : order === 1
      ? LoginVariantTwo
      : LoginVariantThree;

  return (
    <div className="App">
      <Theme>
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={<LayoutLogin Component={LoginForm} />}
            />
            <Route
              path="/password/set"
              element={<Layout Component={SetPassword} />}
            />
            <Route
              path="/password/forgot"
              element={<Layout Component={ResetPassword} />}
            />
            <Route path="/" element={<Navigate replace to="/login" />} />
          </Routes>
        </BrowserRouter>
      </Theme>
    </div>
  );
}

export default App;
