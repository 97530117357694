import React from 'react';

import Logo from '../images/LogoAudience.svg';
import Click from '../images/clickImage.png';

import './loginPage.css';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Layout = ({ Component }) => {
  const useLocalStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
    },
    logo: {
      margin: '4vh',
    },
    grid: {
      height: '80%',
      width: 'inherit',
    },
    image: {
      width: '9rem',
      height: '9rem',
    },
  }));
  const classes = useLocalStyles();

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      data-testid="logo-img-container">
      <Grid item className={classes.logo} data-testid="login-logo-img">
        <img src={Logo} alt="logo" />
      </Grid>
      <Grid
        container
        item
        className={classes.grid}
        direction="column"
        justifyContent="center"
        spacing={3}>
        <Grid item align="center" data-testid="login-logo-img">
          <img src={Click} alt="click" className={classes.image} />
        </Grid>
        <Grid item>
          <Component />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
