import React, { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import Button from '@audiencetown/owk-uitk/dist/components/Button';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { loginService } from '../services/service.auth';

const useStyles = makeStyles(() => ({
  subtext: {
    opacity: 0.6,
  },
  form: {
    width: '100%',
    height: '100%',
  },
}));

const LoginForm = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const validate = () => password.length > 0 && isEmail(email);

    setIsValid(validate());
  }, [email, password]);

  async function handleLogin(e) {
    e.preventDefault();

    try {
      setIsLoading(true);
      const success = await loginService({
        email,
        password,
      });

      if (!success) {
        setError(true);
        setErrorMessage('Invalid credentials');
      }
    } catch (e) {
      setError(true);

      setErrorMessage(
        e.response?.status === 401
          ? 'Invalid credentials'
          : 'Something went wrong',
      );
    }
    setIsLoading(false);
  }
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-around" alignItems="center">
      <Grid container item direction="column" spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            color="textPrimary"
            data-testid="login-title-text">
            Sign in to Audience Town
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleLogin} className={classes.form}>
            <Grid item xs={12} container direction="column" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ 'data-testid': 'login-email-address-area' }}
                  fullWidth
                  name="email"
                  color="primary"
                  type="text"
                  placeholder="Email address"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ 'data-testid': 'login-password-area' }}
                  fullWidth
                  name="password"
                  color="primary"
                  type="password"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  variant="outlined"
                />
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  {error && (
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="error"
                      data-testid="login-error-message-area">
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} />

              <Grid item xs={12}>
                <Button
                  data-testid="login-Sign-In-button"
                  type="submit"
                  disabled={!isValid || isLoading}
                  label="Sign In"
                  color="primary"
                  variant="contained"
                  size="large">
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid xs={9} item>
          <Link
            data-testid="login-forget-password-button"
            component="button"
            color="primary"
            underline="always"
            variant="body2"
            onClick={() => navigate('/password/forgot')}>
            Forgot your password?
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
