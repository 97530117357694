import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import qs from 'query-string';

import config from '../config';

export const loginService = async ({ email, password }) => {
  try {
    const result = await axios.post('auth/login', {
      email: email.toLowerCase(),
      password,
    });
    if (result.data.token) {
      const cookie = new Cookies();
      const timer = moment().add(45, 'minutes');

      cookie.set(
        'at-auth',
        { ...result.data, timer },
        {
          maxAge: 3600,
          path: '/',
          domain: config.domain,
          secure: config.secure,
        },
      );

      const returnUrl = decodeURIComponent(
        qs.parse(window.location.search).returnUrl,
      );

      const redirectUrl = returnUrl === 'undefined' ? '' : returnUrl;

      window.location.href =
        result.data.type === 'admin'
          ? `${config.adminPortalUrl}${redirectUrl}`
          : `${config.selfservicePortalUrl}${redirectUrl}`;

      return true;
    } else return false;
  } catch (e) {
    throw e;
  }
};

export const validateTokenService = async ({ userId, token }) => {
  const res = await axios.post('auth/password/verify', {
    userId,
    token,
  });
  return res?.data?.isValid;
};

export const setPasswordService = async ({ password, token, userId }) => {
  const res = await axios.put('auth/password/set', {
    userId,
    token,
    password,
  });
  return res?.status;
};

export const changePasswordService = async ({
  oldPassword,
  newPassword,
  tokenAuth,
}) => {
  const res = await axios.put(
    'auth/password/change',
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        authorization: `Bearer ${tokenAuth}`,
      },
    },
  );
  return res.status;
};

export const resetPassword = async ({ email }) => {
  const res = await axios.post('auth/password/reset', {
    email: email.toLowerCase(),
  });

  return res?.status;
};
