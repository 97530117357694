import React, { useEffect, useState } from 'react';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import Button from '@audiencetown/owk-uitk/dist/components/Button';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';

import {
  setPasswordService,
  validateTokenService,
} from '../../services/service.auth';

function SetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();

  //check if token is valid.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { t, d } = qs.parse(window.location.search);
    if (!!t && !!d && t !== 'null' && d !== 'null') {
      const checkIsValidToken = await validateTokenService({
        userId: d,
        token: t,
      });
      setIsValidToken(checkIsValidToken);
    }

    setIsChecking(false);
  }, [isValidToken]);

  useEffect(() => {
    const validate = () =>
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword;

    setIsValid(validate());
  }, [confirmPassword, password]);

  async function handleSetPassword() {
    const { t, d } = qs.parse(window.location.search);
    if (
      await setPasswordService({
        password,
        token: t,
        userId: d,
      })
    )
      navigate('/login');
  }

  if (isChecking) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography
          variant="h3"
          color="textPrimary"
          data-testid="setPass-checking-text">
          Checking ...
        </Typography>
      </Grid>
    );
  }

  return isValidToken ? (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item container direction="column" spacing={2} xs={6}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            data-testid="set-your-password-text">
            Please set your password
          </Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            type="password"
            inputProps={{ 'data-testid': 'setPass-password-area' }}
            id="username"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            type="password"
            id="username"
            inputProps={{ 'data-testid': 'setPass-confirm-password-area' }}
            placeholder="Confirm password"
            onChange={(event) => setConfirmPassword(event.target.value)}
            value={confirmPassword}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} />

        <Grid item xs={12} align="center">
          <Button
            data-testid="setPass-submit-button"
            size="large"
            color="primary"
            variant="contained"
            label="submit"
            disabled={!isValid}
            onClick={handleSetPassword}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center" alignItems="center">
      <Typography
        variant="h3"
        color="textPrimary"
        data-testid="invalid-request">
        invalid request
      </Typography>
    </Grid>
  );
}

export default SetPassword;
