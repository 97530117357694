import React from 'react';

import './loginPage.css';
import LoginPageImageTwo from '../images/LoginPageImageTwo.png';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Paper from '@audiencetown/owk-uitk/dist/components/Paper';

import { makeStyles } from '@material-ui/core/styles';

const LoginVariantTwo = ({ Component }) => {
  const useLocalStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.white,
      height: '100%',
    },
    page: {
      backgroundColor: theme.palette.background.white,
      height: '100%',
      width: '100%',
    },
    paper: {
      paddingBottom: '5rem',
      paddingTop: '5rem',
    },
    Image: {
      height: '100vh',
      width: '100%',
    },
  }));
  const classes = useLocalStyles();

  return (
    <Grid container className={classes.root} justifyContent="flex-start">
      <Grid className={classes.page} container>
        <Grid container item xs={5} justifyContent="flex-start">
          <Grid>
            <img
              className={classes.Image}
              src={LoginPageImageTwo}
              alt="LoginPageImageTwo"
              data-testid='login-background-img'
              data-cy={LoginPageImageTwo.split('/')[3]}
            />
          </Grid>
        </Grid>
        <Grid container item xs={7} alignItems="center" justifyContent="center">
          <Grid container item xs={9} justifyContent="center">
            <Grid item xs={10}>
              <Paper variant="outlined" className={classes.paper}>
                <Component />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginVariantTwo;
