import React from 'react';

import Logo from '../images/LogoAudience.svg';
import LoginPageImage from '../images/LoginPageImageThree.png';
import CatImage from '../images/CatImage.png';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Paper from '@audiencetown/owk-uitk/dist/components/Paper';

import { makeStyles } from '@material-ui/core/styles';
import './paper.css';

const LoginVariantThree = ({ Component }) => {
  const useLocalStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
      backgroundImage: `url(${LoginPageImage})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
    },
    page: {
      height: '100%',
      position: 'relative',
      width: '100%',
    },
    logo: {
      margin: '4vh',
    },
    paper: {
      height: 'auto',
      width: '36rem',
      position: 'absolute',
      right: '2rem',
      bottom: '10rem',
      paddingTop: '4rem',
      paddingBottom: '4rem',
    },
    Position: {
      position: 'absolute',
      bottom: '32.7rem',
      right: '2rem',
    },
    size: { width: '13rem', height: 'auto' },
    container: {
      backgroundColor: theme.palette.background.white,
    },
  }));
  const classes = useLocalStyles();

  return (
    <Grid container className={classes.root} justifyContent="flex-start">
      <Grid container className={classes.page}>
        <Grid item xs={12} className={classes.logo}>
          <img src={Logo} alt="logo"  data-testid='login-logo-img' />
        </Grid>
        <Grid item>
          <Paper variant="outlined" className={classes.paper} id="paper">
            <Component />
          </Paper>
        </Grid>
        <Grid item className={classes.Position} id="catImage">
          <img className={classes.size} src={CatImage} alt="CatImage" data-testid='login-background-img' data-cy={CatImage.split('/')[3]}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginVariantThree;
