const PROD = {
  baseUrl: 'https://api.audiencetown.com/api/v2/',
  urlLogin: 'https://app.audiencetown.com/',
  domain: 'audiencetown.com',
  secure: true,
  adminPortalUrl: 'https://admin.audiencetown.com',
  selfservicePortalUrl: 'https://selfservice.audiencetown.com',
};

const STAGING = {
  baseUrl: 'https://api.staging.audiencetown.com/api/v2/',
  urlLogin: 'https://app.staging.audiencetown.com/',
  domain: 'staging.audiencetown.com',
  secure: true,
  adminPortalUrl: 'https://admin.staging.audiencetown.com',
  selfservicePortalUrl: 'https://selfservice.staging.audiencetown.com',
};

const DEV = {
  baseUrl: process.env.REACT_APP_LOGIN_URL || STAGING.baseUrl,
  urlLogin: process.env.REACT_APP_LOGIN_URL || STAGING.urlLogin,
  domain: false,
  secure: false,
  adminPortalUrl: process.env.REACT_APP_ADMINPORTAL_URL || STAGING.adminPortalUrl,
  selfservicePortalUrl:
    process.env.REACT_APP_SELFSERVICE_PORTAL_URL || STAGING.selfservicePortalUrl,
};

const config =
  process.env.REACT_APP_ENV === 'prod'
    ? PROD
    : process.env.REACT_APP_ENV === 'staging'
    ? STAGING
    : DEV;

export default config;
