import React from 'react';

import Logo from '../images/LogoAudience.svg';
import LoginPageImageOne from '../images/LoginPageImageOne.png';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Paper from '@audiencetown/owk-uitk/dist/components/Paper';

import { alpha } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

const LoginVariantOne = ({ Component }) => {
  const useLocalStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.white,
      height: '100%',
      width: 'auto',
    },
    page: {
      backgroundColor: theme.palette.background.white,
      position: 'relative',
      width: '100%',
      height: '88%',
    },
    logo: {
      margin: '4vh',
    },
    Image: {
      backgroundImage: `url(${LoginPageImageOne})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      height: '100%',
      width: '100%',
    },
    paper: {
      height: 'auto',
      width: '36rem',
      position: 'absolute',
      right: '2vw',
      top: '0vh',
      background: alpha(theme.palette.background.white, 0.79),
      paddingTop: '5rem',
      paddingBottom: '5rem',
    },
  }));
  const classes = useLocalStyles();

  return (
    <Grid container className={classes.root} justifyContent="flex-start">
      <Grid container item justifyContent="center">
        <Grid item xs={12} className={classes.logo}>
          <img src={Logo} alt="logo"  data-testid='login-logo-img' />
        </Grid>
        <Grid container item className={classes.page}>
          <Paper variant="outlined" className={classes.paper}>
            <Component />
          </Paper>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-end"
            justifyContent="flex-start">
            <Grid item className={classes.Image}  data-testid='login-background-img' data-cy={LoginPageImageOne.split('/')[3]}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginVariantOne;
