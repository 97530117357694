import React, { useEffect, useState } from 'react';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import Button from '@audiencetown/owk-uitk/dist/components/Button';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { resetPassword } from '../../services/service.auth';
import Snackbar from '@audiencetown/owk-uitk/dist/components/Snackbar';
import MuiAlert from '@audiencetown/owk-uitk/dist/components/Alert';
import Link from '@audiencetown/owk-uitk/dist/components/Link';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsValid(isEmail(email));
  }, [email]);

  async function handleResetPassword() {
    setLoading(true);
    if (isValid) {
      try {
        await resetPassword({ email });
        setSentEmail(true);
      } catch (error) {
        setSnackbar(true);
      }
    }
    setLoading(false);
  }

  return (
    <>
      {sentEmail ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item container direction="column" spacing={2} xs={6}>
            <Grid
              item
              xs={12}
              align="center"
              data-testid="email-check-reset-password-text">
              <Typography>
                Please check the email sent to your email to reset your
                password.
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <Link
                data-testid="return-login-page"
                component="button"
                color="primary"
                underline="always"
                variant="body1"
                onClick={() => navigate('/login')}>
                Return to login page
              </Link>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            container
            direction="column"
            spacing={3}
            xs={6}
            justifyContent="center">
            <Grid item xs={12} align="center">
              <Typography variant="h3">Reset your Password</Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Grid
                item
                xs={12}
                align="center"
                data-testid="enter-your-name-text">
                To reset your password, please enter your username. We will send
                the password
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                data-testid="password reset instructions">
                reset instructions to the email address for this account.
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  color="primary"
                  type="text"
                  id="username"
                  inputProps={{ 'data-testid': 'username' }}
                  error={emailError}
                  placeholder="Please write your username"
                  onChange={(event) => {
                    setEmail(event.target.value);
                    !isEmail(event.target.value)
                      ? setEmailError(true)
                      : setEmailError(false);
                  }}
                  value={email}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center">
              <Button
                data-testid="login-reset-password-button"
                size="large"
                color="primary"
                variant="contained"
                label="Reset Password"
                disabled={loading}
                onClick={handleResetPassword}>
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}>
        <MuiAlert
          data-testid="message-error-muiAlert"
          elevation={6}
          variant="filled"
          onClose={() => setSnackbar(false)}
          severity="error">
          Email not found in the system
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ResetPassword;
